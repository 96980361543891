<template>
  <div class="upload-multiple-images">
    <h1>Upload and Display Multiple Images</h1>

    <v-row>
      <v-col v-for="(file, index) in imageFiles" :key="index" cols="12" md="4">
        <v-file-input
          v-model="imageFiles[index]"
          :label="'Upload Image ' + (index + 1)"
          accept="image/*"
        ></v-file-input>
        <v-img
          v-if="imageFiles[index]"
          :src="getObjectUrl(imageFiles[index])"
          alt="Displayed Image"
        ></v-img>
        <v-btn @click="clearImage(index)">Hapus</v-btn>
      </v-col>
    </v-row>
    <v-btn color="primary" @click="submitUpload">Submit</v-btn>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MultipleImageUploader",
  data() {
    return {
      imageFiles: [null, null, null, null, null],
    };
  },
  watch: {
    imageFiles: {
      handler() {
        // Membuat logika untuk menangani perubahan pada imageFiles di sini
        // console.log('Image files updated:', this.imageFiles);
      },
      deep: true,
    },
  },
  methods: {
    async submitUpload() {
      // Membuat logika untuk mengirim data ke server di sini
      const formData = new FormData();

      this.imageFiles.forEach((file, index) => {
        if (file) {
          formData.append(`image${index + 1}`, file);
        }
      });

      try {
        const response = await axios.post("URL_SERVER", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Server response:", response.data);
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
    getObjectUrl(file) {
      // Menggunakan URL.createObjectURL untuk mendapatkan URL gambar
      return file ? URL.createObjectURL(file) : "";
    },
    clearImage(index) {
      // Menghapus gambar pada indeks tertentu
      this.$set(this.imageFiles, index, null);
    },
  },
};
</script>
